import { useState, useRef, useContext, useEffect } from 'react'
import AuthLayout from '../shared/components/AuthLayout'
import FacebookIcon from '../shared/components/icons/Facebook'
import GoogleIcon from '../shared/components/icons/Google'
import useAuthenticator from '../lib/hooks/useAuthenticator'
import { useRouter } from 'next/router'
import UserContext from '../lib/context/UserContext'
import useFirebaseSetup from '../lib/hooks/useFirebaseSetup'
import { logEvent } from 'firebase/analytics'
import Link from 'next/link'
import configData from '../lib/utils/siteconfig'
import Modal from '../shared/components/Modal'

export default function LogIn({}) {
	const router = useRouter()
	const [errorMessage, setErrorMessage] = useState()
	const emailField = useRef<HTMLInputElement>()
	const passwordField = useRef<HTMLInputElement>()
	// const fullNameField = useRef<HTMLInputElement>()

	const signInInitiated = useRef<boolean>(false)
	const [inputErrors, setInputErrors] = useState<{ email?: string; password?: string }>({})
	const { userContext } = useContext(UserContext)

	const { signInWithEmailAndPassword, signInWithProvider } = useAuthenticator({
		fetchCanCreateUser: true,
	})

	const { analytics } = useFirebaseSetup(['analytics'])

	useEffect(() => {
		if (userContext?.id && signInInitiated.current) {
			router?.push('/')
		}
	}, [userContext?.id, router])

	const signInUsingProvider = async (provider: 'google' | 'facebook') => {
		signInInitiated.current = true
		logEvent(analytics, 'login', { method: provider })
		try {
			await signInWithProvider(provider)
			setErrorMessage(null)
		} catch (err) {
			setErrorMessage(err.message || 'Unknown Error')
		}
	}

	const signinClicked = async (event) => {
		signInInitiated.current = true
		event.preventDefault()
		const email = emailField.current.value
		const password = passwordField.current.value
		// const fullName = fullNameField.current.value.length

		// if (fullName) return

		if (!email) {
			setInputErrors({
				email: 'Oops, your email address is missing.',
			})
			return
		}
		if (!password) {
			setInputErrors({
				password: 'Oops, your password is missing.',
			})
			return
		}
		setInputErrors({})
		logEvent(analytics, 'login', { method: 'email' })

		try {
			await signInWithEmailAndPassword(email, password)
			setErrorMessage(null)
		} catch (err) {
			setErrorMessage(err.message || 'Unknown Error')
		}
	}

	return (
		<AuthLayout
			title1={configData.title}
			title2='Log In'
			description='Log in to your Hide and Seek account.'
			errorMessage={errorMessage}
			setErrorMessage={setErrorMessage.bind(null, null)}>
			<div className='relative flex items-center justify-center bg-gray-50 pb-12 pt-8 px-4'>
				<div className='max-w-md w-full space-y-6 px-10 pt-5 pb-8 bg-white rounded-3xl z-10'>
					<div className='text-center'>
						<h2 className='mt-6 text-4xl font-bold text-darksky font-display'>Login</h2>
						<p className='mt-2 text-sm text-gray-600'>Sign in to your account</p>
					</div>
					<div className='flex flex-row justify-center items-center space-x-3'>
						<span className='w-11 h-11 items-center justify-center inline-flex rounded-full font-bold text-lg  text-white  bg-blue-900 hover:shadow-2xl cursor-pointer transition ease-in duration-300'>
							<button
								title='Log in with Google'
								aria-label='Log in with Google'
								onClick={() => signInUsingProvider('google')}
								className='flex justify-center items-center cursor-pointer bg-redmascot rounded-full w-11 h-11'>
								<GoogleIcon style={{ transform: 'scale(1.5)' }}></GoogleIcon>
							</button>
						</span>
						<span className='w-11 h-11 items-center justify-center inline-flex rounded-full font-bold text-lg  text-white bg-blue-400 hover:shadow-2xl cursor-pointer transition ease-in duration-300'>
							<button
								title='Log in with Facebook'
								aria-label='Log in with Facebook'
								onClick={() => signInUsingProvider('facebook')}
								className='flex justify-center items-center cursor-pointer bg-darkblue rounded-full w-11 h-11'>
								<FacebookIcon style={{ transform: 'scale(1.7)' }}></FacebookIcon>
							</button>
						</span>
					</div>
					<div className='flex items-center justify-center space-x-2'>
						<span className='h-px w-16 bg-gray-300'></span>
						<span className='text-gray-500 font-normal'>OR</span>
						<span className='h-px w-16 bg-gray-300'></span>
					</div>
					<form className='mt-6 space-y-6' onSubmit={signinClicked} id='login'>
						<input type='hidden' name='remember' value='true'></input>
						<div className='relative'>
							<label htmlFor='email' className='text-sm font-bold  tracking-wide'>
								Email
							</label>
							<input
								id='email'
								autoComplete='username'
								className=' w-full text-base py-2 border-b-2 border-gray-300 focus:outline-none focus:border-purple'
								placeholder='Email Address'
								ref={emailField}
								maxLength={100}></input>
							<p className='text-redmascot text-xs'>{inputErrors.email}</p>
						</div>
						<div className='mt-8 content-center'>
							<label htmlFor='password' className='text-sm font-bold  tracking-wide'>
								Password
							</label>
							<input
								id='password'
								className='w-full content-center text-base py-2 border-b-2 border-gray-300 focus:outline-none focus:border-purple'
								placeholder='Enter your password'
								autoComplete='current-password'
								ref={passwordField}
								type='password'
								maxLength={100}></input>
							<p className='text-redmascot text-xs'>{inputErrors.password}</p>
						</div>
						{/* <div className='fixed -top-[900px]'>
							<input
								id='full_name'
								className='w-full content-center text-base py-2 border-2 rounded-md p-3 border-gray-300 focus:outline-none focus:border-purple'
								placeholder='Full Name'
								ref={fullNameField}
								type='text'
								maxLength={100}></input>
						</div> */}

						<div className='flex items-center justify-between'>
							<div className='text-xs ml-auto -mt-5'>
								<Link
									className='text-darkerblue font-medium hover:underline'
									href='/passwordreset'>
									Forgot your password?
								</Link>
							</div>
						</div>
						<div>
							<button
								type='submit'
								form='login'
								className='btn btn-secondary w-full flex justify-center text-lg rounded-full tracking-wide
										font-semibold'>
								Sign in
							</button>
						</div>
						<p className='flex flex-col items-center justify-center mt-10 text-center text-md text-gray-500'>
							<span>Don&apos;t have an account?</span>
							<Link
								className='text-darkerblue font-medium hover:underline cursor-pointer transition ease-in duration-300'
								href='/signup'>
								Sign up
							</Link>
						</p>
					</form>
				</div>
			</div>
			<Modal
				isActive={!!userContext && !signInInitiated.current}
				clicked={() => {
					router.push('/')
				}}
				firstButtonTitle='Go Back'
				title='You are already logged in'
				message=''></Modal>
		</AuthLayout>
	)
}
